document.addEventListener('turbo:load', function () {
	var openMenu = document.querySelector('.menu-open');
	var menu = document.querySelector('.menu');
	var darken = document.querySelector('.darken');
	var clickHide = document.getElementById('click_hide');

	function toggleMenu() {
		menu.classList.toggle('active');
		openMenu.classList.toggle('active');

		if (menu.classList.contains('active')) {
			darken.style.display = 'block';

			if (window.innerWidth <= 600) {
				clickHide.style.display = 'none';
			}
		} else {
			darken.style.display = 'none';

			if (window.innerWidth <= 600) {
				clickHide.style.display = 'flex';
			}
		}
	}

	openMenu.addEventListener('click', function (event) {
		toggleMenu();
		event.stopPropagation();
	});

	menu.addEventListener('click', function (event) {
		event.stopPropagation();
	});

	var menuLinks = menu.querySelectorAll('a');
	menuLinks.forEach(function (link) {
		link.addEventListener('click', function () {
			menu.classList.remove('active');
			openMenu.classList.remove('active');
			darken.style.display = 'none';

			if (window.innerWidth <= 600) {
				clickHide.style.display = 'flex';
			}
		});
	});

	document.addEventListener('click', function (event) {
		var isClickInsideMenu = menu.contains(event.target);

		if (!isClickInsideMenu && menu.classList.contains('active')) {
			toggleMenu();
		}
	});
});

